import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import marked from "marked"

const TeamTemplate = ({ data }) => {
  const person = data.sanityPerson
  return (
    <Layout>
      <Seo title={person.name} />
      <div className="page-headline">
        <div className="hero-headline rewards-program-page">
          <div className="container">
            <div className="section-heading text-center">
              <div className="hero-body">
                <h1 className="hero-page-title mb-4">{person.name}</h1>
                <h2 className="hero-subtitle text-white">{person.position}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content pb-280">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-lg-auto">
              <div
                className="mx-auto border-rounded w-100 text-center"
                style={{
                  height: "auto",
                  // backgroundImage: `url(${person?.image?.asset?.fluid?.srcWebp})`,
                  // backgroundSize: "cover",
                  // backgroundRepeat: "no-repeat",
                  // backgroundPosition: "center center",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={person?.image?.asset?.fluid?.srcWebp}
                  alt={person.name}
                  className="img-fluid mx-auto"
                  style={{
                    borderRadius: "50%",
                    // height: 400,
                    width: "50%",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-10 mx-lg-auto mx-md-auto mt-5 text-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(person.bio !== null ? person.bio : " "),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TeamTemplate

export const teamQuery = graphql`
  query teamQuery($id: String!) {
    sanityPerson(id: { eq: $id }) {
      id
      name
      position
      bio
      image {
        asset {
          fluid {
            srcWebp
          }
        }
      }
    }
  }
`
